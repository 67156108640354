import { Alert, AlertTitle, Box, Button, Grid, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { Login as LoginIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material"
import { useEffect, useRef, useState } from "react";
import { API_URL, OAUTH_ERROR } from "../constants";
import { useHistory, useLocation } from "react-router-dom";

export default function LoginPage() {
  const location = useLocation()
  const history = useHistory()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isLoginError, setIsLoginError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoginSuccess, setIsLoginSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const loginFormRef = useRef<HTMLFormElement>(null)

  const signIn = () => {
    const searchParams = new URLSearchParams(location.search)
    const clientId = searchParams.get("client_id")
    
    if (!loginFormRef.current?.reportValidity() || !clientId) {
      return
    }

    setIsLoginError(false)

    const form = new FormData()

    form.append("client_id", clientId)
    form.append("username", username)
    form.append("password", password)

    const fetchInitOpt: RequestInit = {
      method: "POST",
      body: form
    }

    fetch(`${API_URL}/oauth2/sign-in`, fetchInitOpt)
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          setErrorMessage(data.message)
          setIsLoginError(true)
          return
        }

        setIsLoginError(false)
        setIsLoginSuccess(true)
        
        setTimeout(() => {
          window.location.href = data.result.redirect_uri + "?token=" + data.result.access_token
        }, 1000);
      })
      .catch(err => {
        setErrorMessage(err.message)
        setIsLoginError(true)
      })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const clientId = searchParams.get("client_id")

    if (!clientId) {
      history.push("/error?code=" + OAUTH_ERROR.INVALID_CLIENT_ID)
      return
    }

    setIsLoading(true)

    const form = new FormData()

    form.append('client_id', clientId)

    const fetchInitOpt: RequestInit = {
      method: "POST",
      body: form
    }

    fetch(`${API_URL}/oauth2/validate-client-id`, fetchInitOpt)
      .then(res => res.json())
      .then(data => {
        if (data.message === 'ERROR') {
          history.push("/error?code=" + OAUTH_ERROR.INVALID_CLIENT_ID)
          return
        }

        setIsLoading(false)
      })
      .catch(err => {
        history.push("/error?code=" + OAUTH_ERROR.SERVER_ERROR)
      })
  }, [location.search, history])

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" height="100vh">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Paper>
              <Stack ref={loginFormRef} component="form" spacing={2} padding={2}>
                <Typography variant="h5" align="center" fontWeight="bold">Mufit Login</Typography>
                {isLoginError && (
                  <Alert severity="error">
                    <AlertTitle>Login Failed</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}
                {isLoginSuccess && (
                  <Alert severity="success">
                    <AlertTitle>Login Success</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}
                <TextField
                  label="Username/Email"
                  variant="outlined"
                  onChange={e => setUsername(e.target.value)}
                  value={username}
                  fullWidth
                  required
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type={isPasswordVisible ? "text" : "password"}
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          setIsPasswordVisible(!isPasswordVisible)
                        }}
                      >
                        {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    )
                  }}
                />
                <Box display="flex" justifyContent="end">
                  <Button
                    variant="contained"
                    startIcon={<LoginIcon />}
                    fullWidth
                    onClick={signIn}
                  >
                    Login
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
