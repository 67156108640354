import { BrowserRouter, Switch, Route } from "react-router-dom"
import LoginPage from "./pages/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import MainPage from "./pages/MainPage";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/error">
          <ErrorPage />
        </Route>
        <Route path="/auth">
          <LoginPage />
        </Route>
        <Route path="/">
          <MainPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
