const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:8000/api/v1'

enum OAUTH_ERROR {
  INVALID_CLIENT_ID,
  SERVER_ERROR
}

export {
  API_URL,
  OAUTH_ERROR
}
