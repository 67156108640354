import { Alert, AlertTitle, Box, Grid, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { OAUTH_ERROR } from "../constants"

export default function ErrorPage() {
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState<string>("")

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get("code")

    switch (parseInt(code ?? '')) {
      case OAUTH_ERROR.INVALID_CLIENT_ID:
        setErrorMessage("Invalid client id!")
        break;

      case OAUTH_ERROR.SERVER_ERROR:
        setErrorMessage("Server error!")
        break;

      default:
        setErrorMessage("Unknown error!")
        break;
    }
  }, [location])

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}